import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Link as MuiLink} from '@mui/material';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 300,
  width: '32%',
  display: 'inline-block',
  marginRight: '0.4rem',
  '&:last-child': {
    marginRight: '0'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 300,
    marginRight: 0,
    marginLeft: 0,
    marginBottom: '0.1rem',
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      //border: '4px solid currentColor',
    },
  },
}));

const Image = styled('div')(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: "#f9d194",
  opacity: 0.1,
  borderRadius: '10px',
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function ProductList() {
  const data = useStaticQuery(graphql`
  query siteQuery {
    site {
      siteMetadata {
        storeUrl
        bitsAmazonPageUrl
      }
    }
  }
  `);
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', marginTop: '1rem'}}>
      
        <ImageButton
          focusRipple
        >
          <MuiLink href={data.site.siteMetadata.bitsAmazonPageUrl} rel="noopener" target="_blank">
          <StaticImage
            src="../images/product-images/bits-main1.jpg"
            alt="Product Image"
            loading="eager"
            height={200}

            quality={95}
            formats={["auto", "webp", "avif"]}
          />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              Tools for professionals
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
          </MuiLink>
        </ImageButton>
             
        <ImageButton
          focusRipple
        >
          <MuiLink href={data.site.siteMetadata.storeUrl} rel="noopener" target="_blank">
          <StaticImage
            src="../images/product-images/turmeric-main1.jpg"
            alt="Product Image"
            loading="eager"
            height={200}

            quality={95}
            formats={["auto", "webp", "avif"]}
          />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              Supplements for Active Professionals
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
          </MuiLink>
        </ImageButton>
        
    </Box>
  );
}