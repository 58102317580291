import * as React from "react";
import { HeadFC, Link, PageProps, graphql } from "gatsby";
import { Link as MuiLink, Typography, TypographyProps, styled } from '@mui/material';
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as stylesHome from "../styles/home.module.css";
import { StaticImage } from 'gatsby-plugin-image';
import { orange } from "@mui/material/colors";
import ProductList from "../components/productList";

type DataProps = {
  site: {
    siteMetadata: {
      storeUrl: string
    }
  }
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        storeUrl
      }
    }
  }
`
const TypographyCentered = styled(Typography)<TypographyProps>(({theme}) => ({
  maxWidth: '100%',
  textAlign: 'center',
}))

const IndexPage: React.FC<PageProps<DataProps>> = ({data, location}) => 
{
  return (
  <Layout>
    <div>
      <StaticImage
            className={stylesHome.heroBanner}
            src="../images/banner1-final.png"
            alt="Ultra Handy Banner"
            loading="eager"

            quality={95}
            formats={["auto", "webp", "avif"]}
            style={{ marginRight: '0.3rem' }}
          />
    </div>

      <ProductList/>
      <TypographyCentered variant="h1" sx={{ textAlign: "center" }}>
        Warmly welcome to Ultra Handy!
      </TypographyCentered>

      <TypographyCentered sx={{ textAlign: "center" }}>
        If you want to check out our products like: Bits Keychain Holder, Turmeric Curcumin supplement or Telescopic stool?
        <br/>
        <b>Check out our <MuiLink href={data.site.siteMetadata.storeUrl} underline="hover" rel="noopener" target="_blank">Amazon Store</MuiLink></b>
      </TypographyCentered>
      <TypographyCentered sx={{
        "& a": {
          color: orange[500]
        },
        marginTop: '1.5rem',
      }}>
        Already our customer? Awesome!
        <br/>
        You can <Link to="/register-product">REGISTER YOUR PRODUCT HERE</Link> to get ultimate beneftis.
      </TypographyCentered>
  </Layout>
)}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head: HeadFC<DataProps> = () => <Seo title="Home of Ultra Handy" />

export default IndexPage
